import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MapsPage = () => (
  <Layout>
    <SEO title="Hasznos Magyarországi térképek, közkutak, túrautak, geocaching, közvécék egy helyen" />
    <h1>Hasznos Térképek</h1>
    
    <div className="content-wrapper">
        <div className="header-wrapper">
            <div className="cardify-content-wrapper">
                <a href="https://www.kozkutak.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title"><i className="fas fa-hand-holding-water highlight-upcolor"></i> Közkutak</h5>
                    <p className="cardify-content-alt">Publikus ivókutak Magyaroroszágon</p>

                    <p className="main-content-card-city"><i className="fas fa-tags highlight-upcolor"></i> Ingyenes</p>
                </a>

                <a href="https://www.geocaching.hu/geomap/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title"><i className="fas fa-gift highlight-upcolor"></i> Geocaching</h5>
                    <p className="cardify-content-alt">Geoládák Magyaroroszágon</p>

                    <p className="main-content-card-city"><i className="fas fa-tags highlight-upcolor"></i> Ingyenes</p>
                </a>

                <a href="https://merretekerjek.hu/" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title"><i className="fas fa-bicycle highlight-upcolor"></i> Merretekerjek</h5>
                    <p className="cardify-content-alt">Városi-, túra- és hegyi útvonalak kerékpárosoknak</p>

                    <p className="main-content-card-city"><i className="fas fa-tags highlight-upcolor"></i> Ingyenes</p>
                </a>

                <a href="https://turistautak.hu/maps/mapnik/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title"><i className="fas fa-mountain highlight-upcolor"></i> Turistautak</h5>
                    <p className="cardify-content-alt">Non-profit turistatérkép</p>

                    <p className="main-content-card-city"><i className="fas fa-tags highlight-upcolor"></i> Ingyenes</p>
                </a>
            </div>
        </div>
    </div>
  </Layout>
)

export default MapsPage
